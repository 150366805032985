import { rand } from "../../lib/helpers";
import Firework from "./Firework";
export default class FireworkScene {
    constructor(width, height, context) {
        this.count = 0;
        this.layers = 15;
        this.lowLayers = 5;
        this.highLayers = 100;
        this.distributionInterval = rand(45, 70);
        this.timeSinceSwitch = 0;
        this.switchInterval = rand(20, 31) * 1000; // 20-30 seconds
        this.fireworks = [];
        // Add a fireworkCounter
        this.fireworkCounter = 0;
        this.width = width;
        this.height = height;
        this.ctx = context;
        // Start with low number of layers
        this.layers = this.lowLayers;
    }
    render() {
        var _a;
        // Calculate elapsed time in milliseconds
        const elapsedTime = this.count * (1000 / 60);
        if (elapsedTime > this.timeSinceSwitch + this.switchInterval) {
            // Switch between low and high number of layers
            this.layers = this.layers === this.lowLayers ? this.highLayers : this.lowLayers;
            this.timeSinceSwitch = elapsedTime;
            this.switchInterval = rand(20, 31) * 1000;
            this.distributionInterval = this.distributionInterval <= 15 ? rand(30, 120) : rand(2, 15);
        }
        if (this.count % 15 === 0) {
            this.ctx.filter = 'blur(10px)';
            var imageData = this.ctx.getImageData(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
            this.ctx.putImageData(imageData, 0, 0);
        }
        this.ctx.fillStyle = `rgba(0,0,0, ${this.count == 0 ? 1 : .1})`;
        this.ctx.fillRect(0, 0, this.width, this.height);
        this.ctx.filter = 'none';
        // Increment the fireworkCounter
        this.fireworkCounter++;
        if (this.fireworks.length < this.layers && this.fireworkCounter >= this.distributionInterval) {
            this.fireworks.push(new Firework(rand(0, 360), Math.floor(rand(1, 100)), this.ctx));
            // Reset the fireworkCounter
            this.fireworkCounter = 0;
        }
        (_a = this.fireworks) === null || _a === void 0 ? void 0 : _a.forEach((c, idx) => {
            c.render();
        });
        this.ctx.fillStyle = 'black';
        this.ctx.fillRect(0, 0, 320, 70);
        // Remove any firework that are dead (all Phosphorous balls are burned out)
        this.fireworks = this.fireworks.filter(s => !s.isDead);
        this.count++;
    }
}
