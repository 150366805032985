export default class RGB {
    constructor(red, green, blue, alpha) {
        this.redChannel = red;
        this.greenChannel = green;
        this.blueChannel = blue;
        this.alpha = alpha;
    }
    get alpha() {
        return this._alpha;
    }
    set alpha(value) {
        if (value > 1)
            this._alpha = 1;
        else if (value < 0)
            this._alpha = 0;
        else
            this._alpha = value;
    }
    darken(value) {
        return `rgba(${this.redChannel - value},${this.greenChannel - value},${this.blueChannel - value},${this.alpha})`;
    }
    lighten(value) {
        return `rgba(${this.redChannel + value},${this.greenChannel + value},${this.blueChannel + -value},${this.alpha})`;
    }
    toString() {
        return `rgba(${this.redChannel},${this.greenChannel},${this.blueChannel},${this.alpha})`;
    }
}
