import { getRandomEasing } from "../../../lib/easing";
import { rand } from "../../../lib/helpers";
import Size from "../../../lib/Size";
import Phosphorous from './Phosphorous';
export default class Strobe extends Phosphorous {
    constructor(c1, c2, x, y, cx, cy) {
        super(x, y, cx, cy, new Size(0.8, rand(1, 1.2), 0.8, rand(1, 1.2)), c1, c2, getRandomEasing());
        this.ageLimit = Math.abs(((x - cx) + (y - cy)) / 2) / .5;
        this.frames = rand(2, 100);
    }
    update() {
        super.update();
    }
}
