var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _ClockScene_timeInMs, _ClockScene_ctx, _ClockScene_radiansPerSecond, _ClockScene_radiansPerMinute, _ClockScene_radiansPerHour, _ClockScene_radius, _ClockScene_date;
import { ObjectType } from "../../lib/enums";
import Rect from "../../lib/Rect";
import Size from "../../lib/Size";
import Sprite from "../../lib/Sprite";
import { collision, colorRand, rand } from '../../lib/helpers';
import { easeInBack, easeInElastic, easeInOutQuad } from '../../lib/easing';
import Wall from "../../lib/Wall";
const MAX_PARTICLES = 1000;
let ROTATION_INTERVAL = (Math.PI * 2) / (60 * 60);
let ROTATION_ANGLE = ROTATION_INTERVAL - (Math.PI * 2) / 4;
export default class ClockScene {
    constructor(width, height, context) {
        _ClockScene_timeInMs.set(this, void 0);
        _ClockScene_ctx.set(this, void 0);
        _ClockScene_radiansPerSecond.set(this, void 0);
        _ClockScene_radiansPerMinute.set(this, void 0);
        _ClockScene_radiansPerHour.set(this, void 0);
        _ClockScene_radius.set(this, void 0);
        this.particles = [];
        this.backgroundGlitter = [];
        _ClockScene_date.set(this, void 0);
        this.walls = [];
        this.width = width;
        this.height = height;
        __classPrivateFieldSet(this, _ClockScene_ctx, context, "f");
        __classPrivateFieldSet(this, _ClockScene_date, new Date(), "f");
        __classPrivateFieldSet(this, _ClockScene_radius, 50, "f");
        this.particles = [];
        __classPrivateFieldSet(this, _ClockScene_timeInMs, (__classPrivateFieldGet(this, _ClockScene_date, "f").getHours() * 60 * 60 + __classPrivateFieldGet(this, _ClockScene_date, "f").getMinutes() * 60 + __classPrivateFieldGet(this, _ClockScene_date, "f").getSeconds()) *
            1000 +
            __classPrivateFieldGet(this, _ClockScene_date, "f").getMilliseconds(), "f");
        // In order for things to move slowly around the screen, it
        __classPrivateFieldSet(this, _ClockScene_radiansPerSecond, (Math.PI * 2) / 60, "f");
        __classPrivateFieldSet(this, _ClockScene_radiansPerMinute, __classPrivateFieldGet(this, _ClockScene_radiansPerSecond, "f") / 60, "f"); // 0.10471975511966 / 60 minutes = 0.001745329251994 radians
        __classPrivateFieldSet(this, _ClockScene_radiansPerHour, (__classPrivateFieldGet(this, _ClockScene_radiansPerMinute, "f") * 60) / 12 / 60, "f");
        const WALL_WIDTH = 5;
        const MARGIN = .2 * this.width;
        this.walls.push(
        // LEFT
        new Wall(MARGIN - WALL_WIDTH / 2, MARGIN / 2 - WALL_WIDTH / 2, WALL_WIDTH, this.height - (MARGIN / 2) * 2, colorRand()), 
        // RIGHT
        new Wall(this.width - MARGIN - (WALL_WIDTH / 2), MARGIN / 2 - WALL_WIDTH / 2, WALL_WIDTH, this.height - (MARGIN / 2) * 2, colorRand()), 
        // TOP
        new Wall(MARGIN - WALL_WIDTH / 2, MARGIN / 2 - WALL_WIDTH / 2, this.width - MARGIN * 2 + WALL_WIDTH, WALL_WIDTH, colorRand()), 
        // BOTTOM
        new Wall(MARGIN - WALL_WIDTH / 2, this.height - MARGIN / 2 - (WALL_WIDTH / 2), this.width - MARGIN * 2 + WALL_WIDTH, WALL_WIDTH, colorRand()));
        for (var x = 0; x < 1000; x++) {
            let sizeMin = rand(1, 3);
            let sizeMax = rand(4, 6);
            this.backgroundGlitter.push(new Sprite(new Rect(rand(0, this.width), rand(0, this.height), 10, 10), rand(15, 240), '#000', easeInElastic, rand(-2, 2), rand(-2, 2), new Rect(40, 40, this.width - 40, this.height - 40), new Size(sizeMin, sizeMin, sizeMax, sizeMax), rand(500, 5000), ObjectType.Particle));
        }
    }
    getXYOnCircle(x, y, a, distance) {
        return {
            x: x + Math.cos(a) * distance,
            y: y + Math.sin(a) * distance,
        };
    }
    renderParticleRing(cx, cy, srcRect, radius, angle) {
        const { x, y } = this.getXYOnCircle(cx, cy, ROTATION_ANGLE, radius);
        let sizeMin = rand(.5, 1.5);
        let sizeMax = rand(2.5, 5);
        this.particles.push(new Sprite(new Rect(x, y, 3, 3), rand(15, 300), `rgb(${rand(209, 255)}, ${rand(211, 251)}, ${rand(158, 218)})`, easeInBack, (x - (srcRect.x + srcRect.w / 2)) / rand(radius * 16, radius * 100), (y - (srcRect.y + srcRect.h / 2)) / rand(radius * 16, radius * 100), new Rect(100, this.height / 4, this.width - 100, this.height - this.height / 4), new Size(sizeMin, sizeMin, sizeMax, sizeMax), rand(2000, 10000), ObjectType.Particle));
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").globalAlpha = 1;
        if (angle >= Math.PI * 2) {
            angle = 0;
        }
        while (this.particles.length > MAX_PARTICLES)
            this.particles.shift();
    }
    doCollision(angle, obj, wall) {
        // did we have an intersection?
        if (angle !== null) {
            /// if we're not already in a hit situation, create one
            if (!obj.hit) {
                obj.hit = true;
                const { angles } = wall;
                /// zone 1 - left
                if ((angle >= 0 && angle < angles.tl) ||
                    (angle > angles.bl && angle < 360)) {
                    /// if moving in + direction deflect rect 1 in x direction etc.
                    if (obj.speedX > 0)
                        obj.speedX = -obj.speedX;
                }
                else if (angle >= angles.tl && angle < angles.tr) {
                    /// zone 2 - top
                    if (obj.speedY > 0)
                        obj.speedY = -obj.speedY;
                }
                else if (angle >= angles.tr && angle < angles.br) {
                    /// zone 3 - right
                    if (obj.speedX < 0)
                        obj.speedX = -obj.speedX;
                }
                else {
                    /// zone 4 - bottom
                    if (obj.speedY < 0)
                        obj.speedY = -obj.speedY;
                }
            }
        }
        else
            obj.hit = false; /// reset hit when this hit is done (angle = null)
    }
    renderOtherStuff(objects, checkBoundaries, checkCollisions) {
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").globalAlpha = 0.5;
        objects.forEach((p, idx) => {
            const cp = {
                x: p.x - p.w / 2,
                y: p.y - p.h / 2,
                w: p.w,
                h: p.h,
            };
            __classPrivateFieldGet(this, _ClockScene_ctx, "f").globalAlpha = p.alpha;
            __classPrivateFieldGet(this, _ClockScene_ctx, "f").fillStyle = p.colorString;
            __classPrivateFieldGet(this, _ClockScene_ctx, "f").fillRect(cp.x, cp.y, p.w, p.h);
            // if (RIGHT_PRESSED) p.speedx = Math.abs(p.speedx);
            // if (LEFT_PRESSED) p.speedx = -Math.abs(p.speedx);
            // if (UP_PRESSED) p.speedy = -Math.abs(p.speedy);
            // if (DOWN_PRESSED) p.speedy = Math.abs(p.speedy);
            p.update();
            if (checkBoundaries) {
                p.checkBoundaries();
            }
            if (checkCollisions) {
                this.walls.forEach((wall) => {
                    const angle = collision.collides(cp, wall);
                    if (angle) {
                        wall.hits++;
                        p.colorString = wall.color.toString();
                        p.hitTime = Date.now();
                        p.alpha = 1;
                    }
                    if (p.hitTime > 0) {
                        const a = easeInOutQuad(Date.now() - p.hitTime, 0, 1, p.hitEffectDuration);
                        p.alpha = 1 - a;
                        if (Date.now() > p.hitTime + p.hitEffectDuration) {
                            p.hitTime = 0;
                            p.alpha = 0;
                        }
                    }
                    // if (angle !== null) {
                    //   /// if we're not already in a hit situation, create one
                    //   if (!p.hit) {
                    //     p.hit = true;
                    this.doCollision(angle, p, wall);
                    //   }
                    // } else p.hit = false; /// reset hit when this hit is done (angle = null)
                });
            }
        });
        this.walls.forEach((w) => {
            __classPrivateFieldGet(this, _ClockScene_ctx, "f").fillStyle = w.color.toString();
            __classPrivateFieldGet(this, _ClockScene_ctx, "f").globalAlpha = checkCollisions ? 0.8 : 0.2;
            __classPrivateFieldGet(this, _ClockScene_ctx, "f").fillRect(w.x, w.y, w.w, w.h);
        });
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").globalAlpha = 0.2;
    }
    render() {
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").clearRect(0, 0, this.width, this.height); // clear the screen
        __classPrivateFieldSet(this, _ClockScene_date, new Date(), "f");
        __classPrivateFieldSet(this, _ClockScene_timeInMs, (__classPrivateFieldGet(this, _ClockScene_date, "f").getHours() * 60 * 60 + __classPrivateFieldGet(this, _ClockScene_date, "f").getMinutes() * 60 + __classPrivateFieldGet(this, _ClockScene_date, "f").getSeconds()) *
            1000 +
            __classPrivateFieldGet(this, _ClockScene_date, "f").getMilliseconds(), "f");
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").globalAlpha = .75;
        // Hour Hand Position
        const { x: hoursX, y: hoursY } = this.getXYOnCircle(this.width / 2, this.height / 2, (__classPrivateFieldGet(this, _ClockScene_timeInMs, "f") / 1000) * __classPrivateFieldGet(this, _ClockScene_radiansPerHour, "f") - (Math.PI * 2) / 4, __classPrivateFieldGet(this, _ClockScene_radius, "f") + 150);
        // Minute Hand Position
        const { x: minutesX, y: minutesY } = this.getXYOnCircle(this.width / 2, this.height / 2, (__classPrivateFieldGet(this, _ClockScene_timeInMs, "f") / 1000) * __classPrivateFieldGet(this, _ClockScene_radiansPerMinute, "f") - (Math.PI * 2) / 4, __classPrivateFieldGet(this, _ClockScene_radius, "f") + 100);
        // Second Hand Position
        const { x: secondsX, y: secondsY } = this.getXYOnCircle(this.width / 2, this.height / 2, (__classPrivateFieldGet(this, _ClockScene_timeInMs, "f") / 1000) * __classPrivateFieldGet(this, _ClockScene_radiansPerSecond, "f") - (Math.PI * 2) / 4, __classPrivateFieldGet(this, _ClockScene_radius, "f") + 20);
        // Millisecond Hand Position
        const { x: secondHandOrbiterX, y: secondHandOrbiterY } = this.getXYOnCircle(secondsX, secondsY, (__classPrivateFieldGet(this, _ClockScene_timeInMs, "f") / 1000) * __classPrivateFieldGet(this, _ClockScene_radiansPerSecond, "f") * 60 - (Math.PI * 2) / 4, 25);
        this.renderParticleRing(secondHandOrbiterX, secondHandOrbiterY, new Rect(secondsX, secondsY, 1, 1), 10, 10);
        this.renderOtherStuff(this.backgroundGlitter, true, true);
        this.renderOtherStuff(this.particles, true, true);
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").globalAlpha = 1;
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").beginPath();
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").lineWidth = .5;
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").strokeStyle = "#ddd";
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").moveTo(this.width / 2, this.height / 2);
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").lineTo(secondsX, secondsY);
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").moveTo(this.width / 2, this.height / 2);
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").lineTo(minutesX, minutesY);
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").moveTo(this.width / 2, this.height / 2);
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").lineTo(hoursX, hoursY);
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").moveTo(secondsX, secondsY);
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").lineTo(secondHandOrbiterX, secondHandOrbiterY);
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").stroke();
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").strokeStyle = "#cc0";
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").fillStyle = "#cc0";
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").beginPath();
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").arc(this.width / 2, this.height / 2, 15, 0, 2 * Math.PI);
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").stroke();
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").fill();
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").beginPath();
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").arc(secondsX, secondsY, 8, 0, 2 * Math.PI);
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").fill();
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").stroke();
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").beginPath();
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").arc(minutesX, minutesY, 10, 0, 2 * Math.PI);
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").stroke();
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").fill();
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").beginPath();
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").arc(hoursX, hoursY, 12, 0, 2 * Math.PI);
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").fill();
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").stroke();
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").beginPath();
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").arc(secondHandOrbiterX, secondHandOrbiterY, 5, 0, 2 * Math.PI);
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").fill();
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").textAlign = "center";
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").textBaseline = "top";
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").fillStyle = '#000';
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").font = "10px Helvetica";
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").fillText(`${__classPrivateFieldGet(this, _ClockScene_date, "f").getSeconds()}`, secondsX, secondsY - 5);
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").font = "12px Helvetica";
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").fillText(`${__classPrivateFieldGet(this, _ClockScene_date, "f").getMinutes()}`, minutesX, minutesY - 6);
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").font = "16px Helvetica";
        __classPrivateFieldGet(this, _ClockScene_ctx, "f").fillText(`${__classPrivateFieldGet(this, _ClockScene_date, "f").getHours() > 12 ? __classPrivateFieldGet(this, _ClockScene_date, "f").getHours() - 12 : __classPrivateFieldGet(this, _ClockScene_date, "f").getHours()}`, hoursX, hoursY - 8);
    }
}
_ClockScene_timeInMs = new WeakMap(), _ClockScene_ctx = new WeakMap(), _ClockScene_radiansPerSecond = new WeakMap(), _ClockScene_radiansPerMinute = new WeakMap(), _ClockScene_radiansPerHour = new WeakMap(), _ClockScene_radius = new WeakMap(), _ClockScene_date = new WeakMap();
