import { ObjectType } from "../../lib/enums";
import { calculate, rand } from "../../lib/helpers";
import Rect from "../../lib/Rect";
import Sprite from "../../lib/Sprite";
export default class Snake {
    constructor(width, height, color, frames, effect, size) {
        this.hasTornTroughSpaceTimeFabric = false;
        this.hasFinishedTearingThroughSpaceTimeFabric = false;
        this.angle = 0;
        this.toAngle = 0;
        this.cx = 0;
        this.cy = 0;
        this.baseRadius = 20;
        this.radius = 10;
        this.step = .0125;
        this.particles = [];
        this.frames = 200;
        this.limit = 1000;
        this.width = width;
        this.height = height;
        this.color = color;
        this.frames = frames;
        this.effect = effect;
        this.size = size;
        this.randomize();
    }
    randomize() {
        this.particles = [];
        this.step = .025;
        this.baseRadius = rand(50, 75);
        this.radius = this.baseRadius;
        // this.frames = 120
        this.limit = 200;
        this.cx = this.width / 2 + this.baseRadius;
        this.cy = this.height;
        this.angle = Math.PI; // rand(0, Math.PI * 2)
        this.color = `yellow`;
    }
    updateDirection() {
        // if we have travelled the length of the arc and need to change directions
        // clockwise
        if (this.angle >= this.toAngle && this.step > 0) {
            this.step = -this.step; // change direction
            this.angle -= Math.PI; // rewind the angle 180 degrees ccw 
            this.toAngle = this.angle - rand(0, Math.PI / 2); // set a new to angle between 0 and 180 degrees
            // get a new centerpoint
            const { x: newX, y: newY } = calculate.getVertexFromAngle(this.cx, // current center x
            this.cy, // current center y
            this.angle, // starting angle
            -this.radius * 2 // radius of current arc + 
            );
            // this.radius = this.radius * (1 + this.step);
            this.cx = newX;
            this.cy = newY;
        }
        // counter clockwise
        if (this.angle <= this.toAngle && this.step < 0) {
            this.step = -this.step;
            this.angle += Math.PI;
            this.toAngle = this.angle + rand(0, Math.PI / 2);
            const { x: newX, y: newY } = calculate.getVertexFromAngle(this.cx, this.cy, this.angle, -this.radius * 2);
            // this.radius = this.radius * (1 + this.step);
            this.cx = newX;
            this.cy = newY;
        }
    }
    plot(count) {
        if (!this.hasTornTroughSpaceTimeFabric) {
            for (let c = 0; c < count; c++) {
                const { x, y } = calculate.getVertexFromAngle(this.cx, this.cy, this.angle, this.radius);
                // if we collide with a wall, reset the origin point
                if (x < -50 || x > this.width + 50 || y < -50 || y > this.height + 50) {
                    this.hasTornTroughSpaceTimeFabric = true;
                }
                this.particles.push(new Sprite(new Rect(x, y, 1, 1), this.frames, this.color, this.effect, 0, //rand(-.1, .1),
                0, //rand(-.1, .1),
                new Rect(0, 0, this.width, this.height), this.size, 500, ObjectType.Particle, 0));
                this.updateDirection();
                this.angle += this.step;
            }
            while (this.particles.length > this.limit)
                this.particles.shift();
        }
        if (this.hasTornTroughSpaceTimeFabric) {
            let toRemove = 0;
            while (toRemove < count) {
                this.particles.shift();
                toRemove++;
            }
            this.particles.shift();
            if (this.particles.length == 0)
                this.hasFinishedTearingThroughSpaceTimeFabric = true;
        }
    }
}
