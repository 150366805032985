import { rand } from "../../../lib/helpers";
import Rect from "../../../lib/Rect";
export default class Phosphorous extends Rect {
    constructor(x, y, cx, cy, size, color, secondaryColor, easing) {
        super(x, y, size.min.w, size.min.h);
        this.animationDirection = 1;
        this.animationFrame = 0;
        this.frames = rand(5, 15);
        this.ageLimit = rand(15, 120);
        this.age = 0;
        this.size = size;
        this.color = color;
        this.secondaryColor = secondaryColor;
        this.easing = easing;
        this.vx = (x - cx) / rand(3, 12);
        this.vy = (y - cy) / rand(3, 12);
    }
    getAnimationFrame() {
        if (this.animationDirection === 1) {
            this.animationFrame++;
            if (this.animationFrame >= this.frames)
                this.animationDirection = 0;
        }
        else {
            this.animationFrame--;
            if (this.animationFrame <= 0)
                this.animationDirection = 1;
        }
        return this.animationFrame;
    }
    updateAnimation() {
        const currentFrame = this.getAnimationFrame();
        this.w = this.easing(currentFrame, this.size.min.w, this.size.max.w, this.frames);
        this.h = this.easing(currentFrame, this.size.min.h, this.size.max.h, this.frames);
    }
    update() {
        if (this.ageLimit - this.age > 0) {
            this.x += this.vx;
            this.y += this.vy;
            this.vx += -this.vx / 40;
            this.vy += -this.vy / 40;
            this.vy += 0.035;
            this.updateAnimation();
        }
        else {
            this.isDead = true;
        }
        this.age++;
    }
}
