var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _SeaSpaceScene_ctx, _SeaSpaceScene_drops;
import { easeInElastic, easeLinear } from "../../lib/easing";
import { ObjectType } from "../../lib/enums";
import { rand } from "../../lib/helpers";
import Rect from "../../lib/Rect";
import Size from "../../lib/Size";
import Sprite from "../../lib/Sprite";
export default class SeaSpaceScene {
    constructor(width, height, context) {
        _SeaSpaceScene_ctx.set(this, void 0);
        _SeaSpaceScene_drops.set(this, []);
        this.width = width;
        this.height = height;
        __classPrivateFieldSet(this, _SeaSpaceScene_ctx, context, "f");
        this.init();
    }
    init() {
        for (var x = 0; x < 5000; x++) {
            __classPrivateFieldGet(this, _SeaSpaceScene_drops, "f").push(new Sprite(new Rect(rand(0, this.width), rand(0, this.height), 50, 50), rand(60, 240), `rgb(${rand(0, 0)}, ${rand(0, 0)}, ${rand(0, 125)})`, easeLinear, rand(-4, 4), rand(-4, 4), new Rect(0, 0, this.width, this.height), new Size(5, 5, 10, 10), 1000, ObjectType.Particle));
        }
        for (var x = 0; x < 1000; x++) {
            __classPrivateFieldGet(this, _SeaSpaceScene_drops, "f").push(new Sprite(new Rect(rand(0, this.width), rand(0, this.height), 10, 10), rand(120, 480), `rgb(${rand(0, 0)}, ${rand(0, 0)}, ${rand(200, 255)})`, easeInElastic, rand(-2, 2), rand(-2, 2), new Rect(0, 0, this.width, this.height), new Size(5, 5, 20, 20), 1000, ObjectType.Particle));
        }
        for (var x = 0; x < 50; x++) {
            __classPrivateFieldGet(this, _SeaSpaceScene_drops, "f").push(new Sprite(new Rect(rand(0, this.width), rand(0, this.height), 10, 10), rand(60, 120), `rgb(${rand(100, 200)}, ${rand(0, 0)}, ${rand(0, 0)})`, easeInElastic, rand(-.5, .5), rand(-.5, .5), new Rect(0, 0, this.width, this.height), new Size(10, 10, 20, 20), 1000, ObjectType.Particle));
        }
    }
    render() {
        __classPrivateFieldGet(this, _SeaSpaceScene_ctx, "f").clearRect(0, 0, this.width, this.height); // clear the screen
        __classPrivateFieldGet(this, _SeaSpaceScene_ctx, "f").globalAlpha = .75;
        __classPrivateFieldGet(this, _SeaSpaceScene_drops, "f").forEach((drop, idx) => {
            __classPrivateFieldGet(this, _SeaSpaceScene_ctx, "f").fillStyle = drop.colorString;
            __classPrivateFieldGet(this, _SeaSpaceScene_ctx, "f").fillRect(drop.x - drop.w / 2, drop.y - drop.h / 2, drop.w, drop.h);
            drop.checkBoundaries();
            drop.update();
        });
    }
}
_SeaSpaceScene_ctx = new WeakMap(), _SeaSpaceScene_drops = new WeakMap();
