export default class HSL {
    constructor(h, s, l, a = 1) {
        this.h = h;
        this.s = s;
        this.l = l;
        this.a = a;
    }
    static fromRGB(r, g, b, a = 1) {
        r /= 255;
        g /= 255;
        b /= 255;
        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
        let h;
        let s;
        const l = (max + min) / 2;
        if (max === min) {
            h = s = 0;
        }
        else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            h /= 6;
        }
        return new HSL(h * 360, s * 100, l * 100, a);
    }
    toString() {
        return `hsla(${this.h}, ${this.s}%, ${this.l}%, ${this.a})`;
    }
    lighten(amount) {
        this.l = Math.min(100, this.l + amount);
        return this;
    }
    darken(amount) {
        this.l = Math.max(0, this.l - amount);
        return this;
    }
    saturate(amount) {
        this.s = Math.min(100, this.s + amount);
        return this;
    }
    desaturate(amount) {
        this.s = Math.max(0, this.s - amount);
        return this;
    }
    setAlpha(alpha) {
        this.a = alpha;
        return this;
    }
    setHue(hue) {
        this.h = hue;
        return this;
    }
    clone() {
        return new HSL(this.h, this.s, this.l, this.a);
    }
}
