import { effects } from "../../lib/easing";
import { rand } from "../../lib/helpers";
import Supernova from "./Supernovae";
export default class SupernovaeScene {
    constructor(width, height, context) {
        this.angle = 0;
        this.count = 0;
        this.maxOpacity = .75;
        this.displayDuration = 480;
        this.layers = 1;
        this.supernovae = [];
        this.width = width;
        this.height = height;
        this.ctx = context;
    }
    render() {
        var _a, _b, _c;
        this.ctx.strokeStyle = 'white';
        if (this.count % this.displayDuration === 0) {
            // this.ctx.globalAlpha = 1
            // this.ctx.fillStyle = 'black'
            // this.ctx.fillRect(0, 0, this.width, this.height)
            let f = new Supernova(this.angle, Math.floor(rand(2, 20)), this.ctx);
            f.direction = this.maxOpacity / Math.floor(this.displayDuration / 2);
            f.maxAlpha = this.maxOpacity;
            f.color.alpha = 0;
            f.renderOutlines = Math.random() < .5;
            f.ease = effects[Math.floor(rand(0, effects.length))];
            this.supernovae.push(f);
        }
        if (((_a = this.supernovae) === null || _a === void 0 ? void 0 : _a.length) > this.layers) {
            (_b = this.supernovae) === null || _b === void 0 ? void 0 : _b.shift();
        }
        (_c = this.supernovae) === null || _c === void 0 ? void 0 : _c.forEach((c, idx) => {
            c.render();
        });
        // this.ctx.fillStyle = `rgba(0, 0, 0, ${1 / (this.displayDuration / 20)})`
        // this.ctx.fillRect(0, 0, this.width, this.height)
        this.ctx.globalAlpha = 1;
        this.ctx.fillStyle = 'black';
        this.ctx.fillRect(0, 0, 320, 70);
        this.count++;
    }
}
