import { calculate, rand } from "../../lib/helpers";
import Rect from "../../lib/Rect";
import RGB from "../../lib/RGB";
const _patternSeeds = [2, 3, 4, 5, 6, 7, 8, 9, 10];
const patternSeeds = [2, 3, 4, 5, 6, 7, 8, 9, 10];
const getPatternSeed = () => {
    const seed1 = patternSeeds[Math.floor(rand(0, patternSeeds.length))];
    const seed2 = patternSeeds[Math.floor(rand(0, patternSeeds.length))];
    return Number(rand(seed1, seed2).toFixed(2));
};
const getRandomOptions = (max) => {
    let r = Math.floor(rand(0, 255));
    let g = Math.floor(rand(0, 255));
    let b = Math.floor(rand(0, 255));
    const color = new RGB(r, g, b, 1);
    color.lighten(50);
    const stroke = color.toString();
    const maxRadius = Math.ceil(rand(10, max));
    const angleStep = Math.PI / getPatternSeed();
    return {
        angleStep,
        radiusStepExit: angleStep * rand(10, max),
        radiusStepReturn: angleStep * rand(10, max),
        color,
        stroke,
        minRadius: Math.floor(rand(10, max)),
        maxRadius,
        rate: Math.floor(rand(2, 30)),
    };
};
const paramsToSpirographOptions = (params) => {
    return {
        angleStep: Number(params.get("angleStep")),
        color: JSON.parse(params.get("color")) || null,
        maxRadius: Number(params.get("maxRadius")),
        minRadius: Number(params.get("minRadius")),
        radiusStepExit: Number(params.get("radiusStepExit")),
        radiusStepReturn: Number(params.get("radiusStepReturn")),
        rate: Number(params.get("rate")),
        stroke: params.get("stroke"),
    };
};
const spirographOptionsToParams = (options) => {
    const params = new URLSearchParams();
    Object.entries(options).forEach((value) => {
        params.append(value[0], JSON.stringify(value[1]));
    });
    return params;
};
export default class SpirographScene {
    constructor(width, height, context, options) {
        this.options = null;
        this.particleCenterX = 0;
        this.particleCenterY = 0;
        this.radius = 0;
        this.angle = 0;
        this.direction = 1;
        this.frameCount = 0;
        this.particles = [];
        this.paramsToSpirographOptions = (params) => {
            return {
                angleStep: Number(params.get("angleStep")),
                color: JSON.parse(params.get("color")) || null,
                maxRadius: Number(params.get("maxRadius")),
                minRadius: Number(params.get("minRadius")),
                radiusStepExit: Number(params.get("radiusStepExit")),
                radiusStepReturn: Number(params.get("radiusStepReturn")),
                rate: Number(params.get("rate")),
                stroke: params.get("stroke"),
            };
        };
        this.options = options || paramsToSpirographOptions(new URLSearchParams(window.location.search));
        console.log("OPTIONS", this.options);
        this.width = width;
        this.height = height;
        this.ctx = context;
        this.particleCenterX = this.width / 2;
        this.particleCenterY = this.height / 2;
        if (this.options.color) {
            this.options.color = new RGB(Number(this.options.color.redChannel), Number(this.options.color.greenChannel), Number(this.options.color.blueChannel), 0.5);
        }
        else {
            this.options = getRandomOptions(this.height);
        }
        this.radius = this.options.minRadius;
        if (!this.options.color)
            this.randomize();
    }
    randomize() {
        this.particles = [];
        this.options = getRandomOptions(this.height);
        history.pushState({
            scene: '3',
            params: this.options,
        }, "Scene", `/3?${new URLSearchParams(spirographOptionsToParams(this.options)).toString()}`);
    }
    pushParticles(count) {
        for (var x = 0; x < count; x++) {
            const { x, y } = calculate.getVertexFromAngle(this.particleCenterX, this.particleCenterY, this.angle, this.radius);
            this.particles.push(new Rect(x, y, 0, 0));
            this.angle += this.options.angleStep;
            if (this.direction < 0) {
                this.radius -= this.options.radiusStepReturn; // this.options.angleStep * 500;
            }
            else if (this.direction > 0) {
                this.radius += this.options.radiusStepExit; // this.options.angleStep * 500;
            }
            if ((this.radius > this.options.maxRadius && this.direction > 0) ||
                (this.radius < this.options.minRadius && this.direction <= 0)) {
                this.direction = -this.direction;
            }
        }
    }
    renderParticles(objects) {
        this.ctx.beginPath();
        objects.forEach((drop, idx) => {
            this.ctx.strokeStyle = this.options.color.lighten(50);
            this.ctx.lineWidth = 10 / idx;
            this.ctx.lineTo(drop.x, drop.y);
        });
        this.ctx.stroke();
    }
    render() {
        this.frameCount++;
        if (this.frameCount % 2 === 0) {
            this.ctx.fillStyle = `rgba(0, 0, 0, .05)`;
            this.ctx.fillRect(0, 0, this.width, this.height);
            this.ctx.filter = "none";
        }
        this.pushParticles(this.options.rate);
        this.renderParticles(this.particles);
        while (this.particles.length > 200)
            this.particles.shift();
    }
}
