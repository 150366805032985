import { easeInElastic, } from "../../lib/easing";
import { ObjectType } from "../../lib/enums";
import { calculate } from "../../lib/helpers";
import Rect from "../../lib/Rect";
import Size from "../../lib/Size";
import Sprite from "../../lib/Sprite";
export default class FlowersScene {
    constructor(width, height, context) {
        this.particleCenterX = 0;
        this.particleCenterY = 0;
        this.radius = 255;
        this.angle = 0;
        this.animationDirection = 1;
        this.frame = 0;
        this.direction = -1;
        this.particles = [];
        this.width = width;
        this.height = height;
        this.ctx = context;
        this.particleCenterX = this.width / 2; // rand(this.radius, this.width - this.radius)
        this.particleCenterY = this.height / 2; // rand(this.radius, this.height - this.radius)
    }
    pushParticles(count) {
        for (var x = 0; x < count; x++) {
            const { x, y } = calculate.getVertexFromAngle(this.particleCenterX, this.particleCenterY, this.angle, this.radius);
            this.particles.push(new Sprite(new Rect(x, y, 10, 10), 120, `rgb(${this.radius}, 0, ${this.radius / 4})`, easeInElastic, 0, 0, new Rect(0, 0, this.width, this.height), new Size(4, 4, 10, 10), 1000, ObjectType.Particle, this.frame));
            if (this.animationDirection > 0) {
                this.frame++;
            }
            else if (this.animationDirection < 0) {
                this.frame--;
            }
            if (this.frame >= 60 || this.frame <= 0)
                this.animationDirection = -this.animationDirection;
            this.angle += 0.01564;
            if (this.direction < 0) {
                this.radius -= 6;
            }
            else if (this.direction > 0) {
                this.radius += 6;
            }
            if (this.radius > 254 || this.radius < 0)
                this.direction = -this.direction;
        }
    }
    renderParticles(objects) {
        objects.forEach((drop, idx) => {
            this.ctx.globalAlpha = drop.alpha;
            this.ctx.fillStyle = drop.colorString;
            this.ctx.fillRect(drop.x - drop.w / 2, drop.y - drop.h / 2, drop.w, drop.h);
            drop.update();
            drop.checkBoundaries();
        });
    }
    render() {
        this.ctx.clearRect(0, 0, this.width, this.height); // clear the screen
        this.pushParticles(15);
        this.renderParticles(this.particles);
        while (this.particles.length > 5000)
            this.particles.shift();
    }
}
