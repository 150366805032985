import { easeInOutSine } from "../../lib/easing";
import { rand } from "../../lib/helpers";
import Size from "../../lib/Size";
import Snake from "./Snake";
export default class SnakesScene {
    constructor(width, height, context) {
        this.snakes = [];
        this.radius = 20;
        this.count = 0;
        this.width = width;
        this.height = height;
        this.ctx = context;
    }
    renderOtherStuff(objects) {
        this.ctx.beginPath();
        this.ctx.lineWidth = .5;
        this.ctx.strokeStyle = 'green';
        objects.forEach((drop, idx) => {
            drop.update();
            this.ctx.globalAlpha = drop.alpha;
            this.ctx.fillStyle = drop.colorString;
            this.ctx.arc(drop.x - drop.w / 2, drop.y - drop.h / 2, drop.w, 0, Math.PI * 2);
            this.ctx.fillRect(drop.x - drop.w / 2, drop.y - drop.h / 2, drop.w, drop.h);
        });
        this.ctx.stroke();
    }
    render() {
        var _a, _b, _c;
        this.ctx.clearRect(0, 0, this.width, this.height); // clear the screen
        if (((_a = this.snakes) === null || _a === void 0 ? void 0 : _a.length) < 10 && this.count % 60 === 0) {
            let min = 1;
            let max = 10;
            let size = new Size(min, min, max, max);
            this.snakes.push(new Snake(this.width, this.height, `rgb(${rand(75, 255)},${rand(75, 255)},${rand(75, 255)})`, 45, easeInOutSine, // effects[Math.floor(Math.random() * effects.length)],
            size));
        }
        (_b = this.snakes) === null || _b === void 0 ? void 0 : _b.forEach(r => {
            r.plot(2);
            this.renderOtherStuff(r.particles);
        });
        this.snakes = (_c = this.snakes) === null || _c === void 0 ? void 0 : _c.filter(r => !r.hasFinishedTearingThroughSpaceTimeFabric);
        this.count++;
    }
}
