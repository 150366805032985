import { effects } from "../../lib/easing";
import { ObjectType } from "../../lib/enums";
import { calculate, rand } from "../../lib/helpers";
import Rect from "../../lib/Rect";
import Size from "../../lib/Size";
import Sprite from "../../lib/Sprite";
/**
 * Snakes on a Plane
 */
export default class EscherScene {
    constructor(width, height, context) {
        this.step = .0125; // 
        this.particleCenterX = 0;
        this.particleCenterY = 0;
        this.baseRadius = rand(1, 100);
        this.radius = 0;
        this.frames = 30;
        this.speed = 2;
        this.limit = 500;
        this.length = rand(50, 500);
        this.size = new Size(0, 0, 7, 7);
        this.angle = Math.PI;
        this.effect = effects[Math.floor(Math.random() * effects.length)];
        this.direction = Math.PI * 2 / 60 / 2.5;
        this.count = 0;
        this.color = `rgb(${rand(200, 255)},0,0)`;
        this.toAngle = rand(Math.PI, Math.PI * 2);
        this.particles = [];
        this.width = width;
        this.height = height;
        this.ctx = context;
        this.radius = this.baseRadius;
        // set the center point 
        this.particleCenterX = this.width / 2 + this.radius;
        this.particleCenterY = this.height - this.radius;
    }
    randomize() {
        this.step = rand(.0001, .005);
        this.baseRadius = rand(50, 100);
        this.radius = this.baseRadius;
        this.frames = Math.floor(rand(15, 60));
        this.speed = rand(2, 8);
        this.limit = rand(250, 2500);
        this.particles = [];
        let min = rand(0, 5);
        let max = rand(min, 20);
        this.size = new Size(min, min, max, max);
        this.particleCenterX = this.width / 2 + this.radius;
        this.particleCenterY = this.height - this.radius;
        this.angle = Math.PI;
        this.direction = Math.abs(this.direction);
    }
    renderPoints(objects) {
        objects.forEach((drop, idx) => {
            this.ctx.globalAlpha = drop.alpha;
            this.ctx.fillStyle = drop.colorString;
            this.ctx.strokeStyle = '#fff';
            this.ctx.lineWidth = .5;
            this.ctx.strokeRect(drop.x - drop.w / 2, drop.y - drop.h / 2, drop.w, drop.h);
            //this.ctx.fillRect(drop.x - drop.w / 2, drop.y - drop.h / 2, drop.w, drop.h);
            drop.update();
            // drop.checkBoundaries();
        });
    }
    plotPoints(count) {
        for (let c = 0; c < count; c++) {
            const { x, y } = calculate.getVertexFromAngle(this.particleCenterX, this.particleCenterY, this.angle, this.radius);
            // if we collide with a wall, reset the origin point
            if (x < -50 || x > this.width + 50 || y < -50) {
                this.radius = this.baseRadius;
                this.particleCenterX = this.width / 2 + this.radius;
                this.particleCenterY = this.height - this.radius;
                this.angle = Math.PI;
                this.direction = Math.abs(this.direction);
                this.color = `rgb(${rand(20, 255)},${rand(20, 255)},${rand(20, 255)})`;
                this.effect = effects[Math.floor(Math.random() * effects.length)];
            }
            this.particles.push(new Sprite(new Rect(x, y, 0, 0), this.frames, this.color, this.effect, rand(-.05, .05), rand(-.25, -.5), new Rect(0, 0, this.width, this.height), this.size, 500, ObjectType.Particle, 0));
            // run the render function
            this.angle += this.direction;
            if (this.angle >= this.toAngle && this.direction > 0) {
                this.direction = -this.direction;
                this.angle -= Math.PI;
                this.toAngle = -(rand(-Math.PI, Math.PI));
                const { x: newX, y: newY } = calculate.getVertexFromAngle(this.particleCenterX, this.particleCenterY, this.angle, -this.radius * (2 + this.step));
                this.radius = this.radius * (1 + this.step);
                this.particleCenterX = newX;
                this.particleCenterY = newY;
            }
            // counter clockwise
            if (this.angle <= this.toAngle && this.direction < 0) {
                this.direction = -this.direction;
                this.angle += Math.PI;
                this.toAngle = rand(-Math.PI * 2, Math.PI * 2);
                const { x: newX, y: newY } = calculate.getVertexFromAngle(this.particleCenterX, this.particleCenterY, this.angle, -this.radius * (2 + this.step));
                this.radius = this.radius * (1 + this.step);
                this.particleCenterX = newX;
                this.particleCenterY = newY;
            }
        }
    }
    renderStem(r) {
        this.ctx.globalAlpha = 1;
        this.ctx.strokeStyle = "#fff";
        this.ctx.lineWidth = this.size.max.w;
        this.ctx.beginPath();
        this.ctx.moveTo(this.width / 2, this.height);
        this.ctx.lineTo(this.width / 2, this.height - r / 2);
        this.ctx.stroke();
    }
    render() {
        this.ctx.clearRect(0, 0, this.width, this.height); // clear the screen
        this.count = this.count + 1; // count render calls
        this.plotPoints(this.speed); // the number of points correlates to speed
        this.renderPoints(this.particles); // 
        //this.renderStem(this.baseRadius * 1.5)
        // Request to do this again ASAP
        while (this.particles.length > this.limit)
            this.particles.shift();
    }
}
