var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Rect_instances, _Rect_angles, _Rect_angle;
export default class Rect {
    /**
     * Defines a new Rectangle
     * @param {Number} x
     * @param {Number} y
     * @param {Number} w
     * @param {Number} h
     */
    constructor(x, y, w, h) {
        _Rect_instances.add(this);
        _Rect_angles.set(this, null);
        this.x = x;
        this.y = y;
        this.w = w;
        this.h = h;
        __classPrivateFieldSet(this, _Rect_angles, null, "f");
    }
    get angles() {
        if (!__classPrivateFieldGet(this, _Rect_angles, "f"))
            __classPrivateFieldSet(this, _Rect_angles, {
                tl: __classPrivateFieldGet(this, _Rect_instances, "m", _Rect_angle).call(this, this.x, this.y),
                tr: __classPrivateFieldGet(this, _Rect_instances, "m", _Rect_angle).call(this, this.x + this.w, this.y),
                bl: __classPrivateFieldGet(this, _Rect_instances, "m", _Rect_angle).call(this, this.x, this.y + this.h),
                br: __classPrivateFieldGet(this, _Rect_instances, "m", _Rect_angle).call(this, this.x + this.w, this.y + this.h),
            }, "f");
        return __classPrivateFieldGet(this, _Rect_angles, "f");
    }
}
_Rect_angles = new WeakMap(), _Rect_instances = new WeakSet(), _Rect_angle = function _Rect_angle(x, y) {
    var dx = this.x + this.w / 2 - x;
    var dy = this.y + this.h / 2 - y;
    var angle = (Math.atan2(dy, dx) * 180) / Math.PI;
    if (angle < 0)
        angle += 360;
    return angle;
};
